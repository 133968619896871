import { useState } from "react"

import { Box, Container, Tabs, Tab, AppBar } from "@mui/material"

import TopRank from "./TopRanking"
import BestOfTheMonth from "./BestOfTheMonth"
import TabPanel from "../../components/TabPanel"

import { a11yProps } from "../../utils/utils"

const TopRanking = () => {

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container className="container--single">
      <Box className="glassmorphism p-5">
        <AppBar position="static" sx={{ background: "#12121200" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Top Ranking" {...a11yProps(0)} />
            <Tab label="Migliore del mese" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <Box className="p-0">
          <TabPanel secondHalf value={value} index={0}>
            <TopRank/>
          </TabPanel>
          <TabPanel secondHalf value={value} index={1}>
            <BestOfTheMonth/>
          </TabPanel>
        </Box>
      </Box>
    </Container>
  )
}

export default TopRanking
