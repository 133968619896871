import { useEffect, useRef, useState } from "react"
import classnames from "classnames"
import { Link, useLocation, useNavigate } from "react-router-dom"
import useAuthStore from "../hooks/authStore"
import { imageBasePath } from "../utils/constants"
import { Avatar } from "@mui/material"
import logo from "../assets/Logo-XRAID-r.png"

const Drawer = () => {
  const { pathname } = useLocation()
  const asideRef = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()

  const pages = [
    { label: "Home", to: "/" },
    { label: "Top Ranking", to: "/TopRanking" },
    { label: "Modalità", to: "/modalita" },
    { label: "Arene", to: "/arene" }
  ]

  const {
    isLogged,
    userID,
    userAvatar,
    logout: logoutAction
  } = useAuthStore()

  if (isLogged) {
    pages.push({
      label: "Profilo",
      to: "/Profilo/" + userID
    }, {
      label: "Logout",
      to: "/",
      onClick: () => logoutAction()
    })
  } else {
    pages.push({
      label: "Login",
      to: "/login"
    })
    // ,{
    //   label: "Registrati",
    //     to: "/registrati"
    // }
  }

  pages.push({
    label: "Apri un'arena",
    onClick: () => window.open("https://www.lasergame-xraid.com/index.php/en/", "_blank")
  })

  useEffect(() => {
    if (isOpen) {
      document.body.parentElement.style.overflowY = "hidden"
    } else {
      document.body.parentElement.style.overflowY = ""
    }
  }, [isOpen])

  return (
    <>
      <div className="navbar__close-button--container">
        <div className="menu-icon">
          <input
            checked={isOpen}
            onClick={() => {
              setOpen(!isOpen)
            }}
            className="menu-icon__checkbox"
            type="checkbox"
            readOnly
          />
          <div>
            <span/>
            <span/>
          </div>
        </div>
      </div>

      <aside className={isOpen ? "active disable-scroll" : ""} ref={asideRef}>
        <nav id="navbar">
          <div className="navbar__logo" onClick={() => {
            navigate("/")
            setOpen(false)
          }}>
            <img className="max-w-[30vw]" src={logo} alt=""/>
            <hr/>
          </div>
          {
            pages.map(
              ({ to, label, onClick }, i) => {
                const isActive = pathname === to

                return (
                  <div
                    key={i}
                    className={classnames({
                      navbar__item: true,
                      "navbar__item--animate": !isActive
                    })}>
                    {
                      to ?
                        <Link
                          to={to}
                          className={classnames({
                            "navbar__item--link link-null": true,
                            "navbar__item--active": isActive,
                            "text-muted flex flex-row gap-2 items-center": !isActive,
                            "!text-red-300": label === "Logout"
                          })}

                          onClick={(event) => {
                            if (onClick) {
                              event.preventDefault()
                              event.stopPropagation()
                              onClick && onClick()
                            }
                            setOpen(false)
                          }}
                        >
                          {
                            label === "Profilo" && (
                              <Avatar
                                sx={{ margin: "0 auto" }}
                                src={imageBasePath + userAvatar}
                              />
                            )
                          }
                          {label}
                        </Link>
                        :
                        <a
                          href="/"
                          className={classnames({
                            "cursor-pointer navbar__item--link link-null": true,
                            "text-muted": !isActive
                          })}
                          onClick={(event) => {
                            if (onClick) {
                              onClick(event)
                              event.preventDefault()
                              event.stopPropagation()
                            }
                            setOpen(false)
                          }}
                        >{label}</a>
                    }
                  </div>
                )
              }
            )
          }
        </nav>
      </aside>
    </>
  )
}

export default Drawer
