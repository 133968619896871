import { useEffect, useState } from "react"

import { callProcess } from "../utils/utils"

export const DOCUMENT_ID = {
  PRIVACY: 1,
  NOTE: 2,
  SCARICORESPONSABILITA: 3
}

const useDocument = (id) => {
  const [document, setDocument] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)

    if (!Object.values(DOCUMENT_ID).includes(id)) {
      // eslint-disable-next-line no-console
      console.error("Invalid document id")
      setError("Invalid document id")
      setLoading(false)
      return
    }

    (async() => {
      try {
        const data = await callProcess({ processid: "app_getstaticpages", documentID: id })

        setDocument(data.document)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("EROORRRRRR", error)
        setError(error)
      } finally {
        setLoading(false)
      }

    })()
  }, [id])

  return { document, loading, error }
}

export default useDocument
