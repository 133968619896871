import { useEffect, useState } from "react"

import { CircularProgress, Container, TextField } from "@mui/material"

import UserCard from "../../components/UserCard"
import { imageBasePath } from "../../utils/constants"
import useFetcher from "../../hooks/useFetcher"

const TopRank = () => {
  const { callProcess, data, isLoading } = useFetcher()
  const [search, setSearch] = useState("")

  useEffect(() => {
    callProcess("POST", {
      processid: "app_toprank"
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoading) {
    return (
      <Container className="p-0 w-full text-center">
        <CircularProgress className="circular-progress--centered mx-auto row-span-3"/>
      </Container>
    )
  }

  return (
    <Container className="p-0 w-full">
      <div
        className="container--2-row max-md:p-0">
        <TextField
          required
          label="Cerca giocatore"
          className="col-span-2 !my-5 max-md:col-span-1"
          onChange={(event) => {
            const value = event?.target?.value

            setSearch(value)
          }}
        />

        {
          data && data?.dati.length ?
            data?.dati.map((toprank, index) => {
              const {
                name,
                user_id,
                avatar,
                IMMAGINE,
                arenaprovenienza,
                CT_ARENAPROVENIENZA,
                CT_RANK_TABLE,
                DESCRIPTION,
                score
              } = toprank

              if (search && !name.toLowerCase().includes(search.toLowerCase())) {
                return null
              }

              return (
                <UserCard
                  key={"top-rank-" + index + "-" + user_id}
                  userdata={{
                    avatar: IMMAGINE ? imageBasePath + IMMAGINE : avatar,
                    username: name,
                    user_id,
                    arenaprovenienza,
                    arena_id: CT_ARENAPROVENIENZA,
                    rank_id: CT_RANK_TABLE,
                    rank: DESCRIPTION,
                    position: index + 1,
                    score
                  }}
                />
              )
            })
            :
            <div className="text-center w-full col-span-3">
              <h2 className="text-2xl text-white">Nessun dato disponibile</h2>
            </div>
        }
      </div>
    </Container>
  )
}

export default TopRank
