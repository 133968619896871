import { useEffect } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import { Box, CircularProgress, Container, Typography, Button } from "@mui/material"

import useFetcher from "../../hooks/useFetcher"

const HandleRefreshPassword = () => {

  const { token } = useParams()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const { callProcess, data } = useFetcher()

  useEffect(() => {
    const action = params.get("action") || "reset_password"
    console.log({ action, processid: action === "confirm_account" ? "confirm_accountact" : "app_resetpasswordact" })
    callProcess("POST", {
      processid: action === "confirm_account" ? "confirm_accountact" : "app_resetpasswordact",
      body: { TOKEN: token }
    })
  }, [token, params])

  useEffect(() => {
    console.log(data)
  }, [data])
  return (
    <Container>
      <Box className="glassmorphism flex flex-col items-center p-10">
        <Typography variant="h4" className="" color="primary">
          {params.get("action") === "confirm_account" ? "Conferma account" : "Reset password"}
        </Typography>
        <Typography className="text-white" variant="body1" sx={{ margin: "1rem" }}>
          {
            data ?
              `${data.message}. È stata inviata una mail con la nuova password` :
              <CircularProgress className="circular-progress--centered"/>
          }
        </Typography>
        <br/>
        {
          data?.status && data?.success && !data?.fail ?
            <Button
              onClick={() => navigate("/Login")}
              variant="solid"
              sx={{ textDecoration: "underline", margin: "auto" }}>Vai al login</Button>
            :
            <></>
        }
      </Box>
    </Container>
  )
}

export default HandleRefreshPassword
