import { useEffect } from "react"
import { useParams } from "react-router-dom"

import { Container, Avatar, Box, Typography } from "@mui/material"

import useFetcher from "../../hooks/useFetcher"

const DetailModalita = () => {
  const params = useParams()

  const { callProcess, data } = useFetcher()

  useEffect(() => {
    callProcess("POST", {
      processid: "app_modalities",
      body: {
        ID: params.pageID
      }
    })

    // eslint-disable-next-line
  }, [])

  return (
    <Container className="container--single">
      <Box
        className="glassmorphism !p-5"
      >
        {
          data?.modality &&
          <>
            <div className="flex flex-row items-center justify-center gap-4 ">
              <Avatar
                src={data.modality.LOGOENCODED}
                alt={data.modality.LOGO}
                variant="square"
                className="rounded-lg"
                sx={{ width: "10vh", height: "10vh" }}
              />

              <Typography variant="h4" color="primary">
                {data.modality.TITOLO}
              </Typography>
            </div>
            <hr className="w-2/3 my-3 mx-auto border-cyan"/>
            <div className="!text-white" dangerouslySetInnerHTML={{ __html: data.modality.DESCRIZIONE }}/>
          </>
        }
      </Box>
    </Container>
  )
}

export default DetailModalita
