import { useEffect, useState } from "react"
import { Button } from "@mui/material"
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop"

const InstallPWAButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null)
  const [isInstalled, setIsInstalled] = useState(sessionStorage.getItem("install") === "true")

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault()
      setDeferredPrompt(e)
    }

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt)

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt)
    }
  }, [])

  const handleInstallClick = async() => {
    if (!deferredPrompt) {
      setIsInstalled(sessionStorage.getItem("install") === "true")
      return
    }

    deferredPrompt.prompt()
    const { outcome } = await deferredPrompt.userChoice

    if (outcome === "accepted") {
      sessionStorage.setItem("install", "true")
      setIsInstalled(true)
      // eslint-disable-next-line no-console
      console.log("Utente ha accettato l'installazione della PWA")
    } else {
      // eslint-disable-next-line no-console
      console.log("Utente ha rifiutato l'installazione della PWA")
    }

    setDeferredPrompt(null)
  }

  return (
    !isInstalled ?
      (
        <Button
          variant="contained"
          onClick={handleInstallClick}
          disabled={!deferredPrompt}
          className="rounded-lg"
        >
          Installa App <InstallDesktopIcon sx={{ marginLeft: "1.5rem", display: "inline" }}/>
        </Button>
      )
      : (<></>)
  )
}

export default InstallPWAButton
