// import CountUp from "react-countup"
import { Box, Container, Typography } from "@mui/material"
import { styled } from "@mui/material"
import InstallPWAButton from "../components/InstallPWAButton"

import logo from "../assets/Logo-XRAID-r.png"

const Logo = styled("img")({
  width: "100%", // height: "20vh",
  display: "flex",
  margin: "auto"
})

const Home = () => {

  return (
    <Container maxWidth="lg">
      <Box sx={{ marginLeft: "auto", marginRight: "auto", width: "100%", padding: "5%" }}>
        <Logo sx={{ width: "100%" }} src={logo} alt="X-Raid Logo"/>
        <Box className="flex flex-col flex-nowrap justify-center items-center mt-3 glassmorphism p-5 shadow-cs">
          <Typography component="p" className="text-center text-cyan">
            Benvenuto nel Portale Giocatori XRaid Lasergame. <br/>
            Qui puoi vedere i tuoi punteggi, condividere il tuo profilo, acquisire esperienza e
            diventare il miglior giocatore di Laser Game del mondo
            Registrati e gioca in tutte le arene XRaid Lasergame
            con la tua Membership Card personale
          </Typography>
          <hr className="w-2/3 mx-auto bg-cyan-300 text-cyan-300 my-5 color-cyan-300"/>
          <InstallPWAButton/>
        </Box>
      </Box>
    </Container>
  )
}

export default Home
