import { useState, useReducer, useEffect } from "react"
import { Link } from "react-router-dom"

import {
  Box, TextField, CircularProgress, Button,
  InputLabel, FormControl, Select, MenuItem
} from "@mui/material"

import { callProcessPath, dbName } from "../../utils/constants"
import toast from "react-hot-toast"
import useAuthStore from "../../hooks/authStore"

import ImageUploader from "../../components/ImageUploader"

const UserSettings = () => {
  const { username, password, userdata, isLogged } = useAuthStore()

  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)

  const [formInput, setFormInput] = useReducer(
    (state, newState) => {
      if (disabled) {
        setDisabled(false)
      }

      return ({
        ...state,
        ...newState
      })
    },
    {
      NOME: null,
      COGNOME: null,
      USERNAME: null,
      DISPLAY_NAME: null,
      EMAIL: null,
      INDIRIZZO: null,
      DATANASCITA: null,
      RFID: null,
      CAP: null,
      CITTA: null,
      PROVINCIA: null,
      NAZIONE: null,
      TELEFONO: null,
      AVATAR_ENCODED: null,
      IMMAGINE: null,
      UPLOADEDFILENAME: null,
      CRM_SESSO: ""
    }
  )

  useEffect(() => {
    if (!isLogged) {
      return
    }

    const formData = {
      NOME: userdata.useranagrafica.NOME || "",
      COGNOME: userdata.useranagrafica.COGNOME || "",
      USERNAME: userdata.userdata.LOGIN || "",
      DISPLAY_NAME: userdata.useranagrafica.DISPLAY_NAME || "",
      EMAIL: userdata.useranagrafica.USERS_EMAIL || "",
      INDIRIZZO: userdata.useranagrafica.INDIRIZZO || "",
      DATANASCITA: userdata.useranagrafica.DATANASCITA || "",
      RFID: userdata.useranagrafica.RFID || "",
      CAP: userdata.useranagrafica.CAP || "",
      CITTA: userdata.useranagrafica.CITTA || "",
      PROVINCIA: userdata.useranagrafica.PROVINCIA || "",
      NAZIONE: userdata.useranagrafica.NAZIONE || "",
      TELEFONO: userdata.useranagrafica.TELEFONO || "",
      AVATAR_ENCODED: userdata.AVATAR_ENCODED || "",
      UPLOADEDFILENAME: userdata.UPLOADEDFILENAME || "",
      CRM_SESSO: userdata.useranagrafica.CRM_SESSO || "M"
    }

    setFormInput(formData)
    setLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, userdata])

  const handleInput = evt => {
    const name = evt.target.name
    const newValue = evt.target.value
    setFormInput({ [name]: newValue })
  }

  const handleSubmit = () => {
    const data = formInput

    const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&language=&processid=app_userdataupdate"

    fetch(url, {
      method: "POST",
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          toast.success("Dati modificati correttamente")
          // setLateFormInput(formInput)
        } else {
          throw new Error(response.message)
        }
      })
      .catch(error => {
        toast.error(error.message || "Si è verificato un errore, riprova")
        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  if (!isLogged) {
    return (<></>)
  }

  if (loading) {
    return (
      <Box className="w-full h-full justify-center flex flex-col items-center p-10">
        <CircularProgress className="circular-progress--centered"/>
      </Box>
    )
  }

  return (
    <form
      className="!w-full !h-full p-5 flex flex-col gap-3 items-center container--scrollable"
      style={{ maxHeight: "unset" }}>
      <div
        className="p-3 my-2 mx-auto gap-3 grid grid-cols-2 max-md:grid-cols-1 w-4/5"
      >
        {
          [
            { key: "NOME", type: "text" },
            { key: "COGNOME", type: "text" },
            { key: "USERNAME", type: "text" },
            { key: "DISPLAY_NAME", type: "text" },
            { key: "EMAIL", type: "email" },
            { divider: true },
            { key: "INDIRIZZO", type: "text" },
            { key: "CITTA", type: "text" },
            { key: "CAP", type: "number" },
            { key: "PROVINCIA", type: "text" },
            { key: "NAZIONE", type: "text" },
            { divider: true },
            { key: "DATANASCITA", type: "date" },
            { key: "TELEFONO", type: "number" },
            { key: "RFID", type: "number", readOnly: true }
          ].map(({ key, type, divider = false, readOnly = false }, index) => {
            const label = (key || "").replace("CRM_", "").replace("_", " ")

            if (divider) {
              return (
                <hr className="w-2/3 mx-auto my-3 col-span-2 max-md:col-span-1" key={`divider-${index}`}/>
              )
            }

            return (
              <TextField
                key={index}
                id={key}
                name={key}
                label={label}
                type={type}
                value={formInput[key]}
                onChange={handleInput}
                InputProps={{
                  readOnly
                }}
                {...(type === "date" ? { InputLabelProps: { shrink: true } } : {})}
              />
            )
          })
        }

        <FormControl>
          <InputLabel id="label-CRM_SESSO">Sesso</InputLabel>
          <Select
            id="CRM_SESSO"
            name="CRM_SESSO"
            labelId="label-CRM_SESSO"
            value={formInput.CRM_SESSO}
            onChange={handleInput}
          >
            <MenuItem value="M">Maschio</MenuItem>
            <MenuItem value="F">Femmina</MenuItem>
          </Select>
        </FormControl>

        <hr className="w-2/3 mx-auto my-3 col-span-2 max-md:col-span-1"/>

        <div className="flex flex-col items-center col-span-2 max-md:col-span-1">
          <ImageUploader
            onChange={({ imageUrl, image }) => {
              setFormInput({ AVATAR_ENCODED: imageUrl, UPLOADEDFILENAME: image.name })
              setFormInput({ IMMAGINE: image })
            }}
          />
          <InputLabel>Inserisci avatar</InputLabel>
        </div>

      </div>


      <hr className="w-2/3 mx-auto my-3 bg-cyan"/>

      <Button
        className="w-3/5 mx-auto"
        variant="outlined"
        size="large" onClick={handleSubmit}
        disabled={disabled}
      >Salva</Button>

      <Button
        className="w-2/5 mx-auto underline min-w-max"
        variant="text"
        component={Link}
        to="/ModificaPassword"
      >Modifica Password</Button>
    </form>
  )
}

export default UserSettings

