import { Typography } from "@mui/material"
import StatsGraph from "./StatsGraph"

const Statistics = ({ data }) => {
  return (
    <>
      <Typography variant="h5" className="!mb-3 text-white">Statistiche</Typography>

      <div className="statistic__container">
        <div>
          <Typography variant="body2" className="statistic__title">Punteggio</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.HONOR_SCORE}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Rank</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.RANK}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Modalita preferita</Typography>
          <Typography variant="h6" className="statistic__value">{data.PREFERRED_GAME_MODE || (
            <br/>)}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Partite</Typography>
          <Typography variant="h6"
            className="statistic__value">{+data.MATCHS_DEFEATS + +data.MATCHS_WINS}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Vittorie</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.MATCHS_WINS ? data.MATCHS_WINS : 0}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Sconfitte</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.MATCHS_DEFEATS ? data.MATCHS_DEFEATS : 0}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Obbiettivi</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.global_user_games_data.global_captures}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Colpi sparati</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.global_user_games_data.global_totshots}</Typography>
        </div>
        <div>
          <Typography variant="body2" className="statistic__title">Precisione</Typography>
          <Typography variant="h6"
            className="statistic__value">{data.global_user_games_data.global_accuarcy}</Typography>
        </div>
      </div>

      <StatsGraph
        className="grid gap-2 !h-2/3 w-2/3 mt-3 mx-auto max-md:!w-full max-md:!h-full"
        data={data}
      />
    </>
  )
}

export default Statistics
