import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import {
  Box,
  CircularProgress,
  Avatar,
  Container,
  Card,
  Typography,
  CardContent, Button
} from "@mui/material"

import useFetcher from "../../hooks/useFetcher"

const Modalita = () => {
  const navigate = useNavigate()

  const { callProcess, data, isLoading } = useFetcher()
  useEffect(() => {
    callProcess("POST", {
      processid: "app_modalities"
    })

    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return (
      <Container className="container--single min-h-[50vh]">
        <Box className="glassmorphism flex items-center justify-center py-10 min-h-[50vh]">
          <CircularProgress className="circular-progress--centered"/>
        </Box>
      </Container>
    )
  }

  return (
    <Container
      sx={{ padding: "5% !important" }}
    >
      <Box
        className="h-max glassmorphism"
        sx={{ display: "flex" }}
      >
        {
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3 max-md:p-5 p-10 h-full">
            {
              data?.modalities && data.modalities.map((modalita, index) => (
                <Card
                  className="p-3 w-full rounded-lg shadow-cs h-min"
                  key={index}
                  style={{ height: "100%" }}
                >
                  <CardContent
                    onClick={() => {
                      navigate("/modalita/" + modalita.ID)
                    }}
                    className="cursor-pointer flex flex-col gap-3"
                    style={{ height: "100%" }}

                  >
                    <div className="flex flex-row items-center justify-center gap-4 ">
                      <Avatar
                        src={modalita.LOGOENCODED}
                        alt={modalita.LOGO}
                        variant="square"
                        className="rounded-lg"
                        sx={{ width: "10vh", height: "10vh" }}
                      />
                      <Typography variant="h5">{modalita.TITOLO}</Typography>
                    </div>
                    <Typography variant="body2">{modalita.ESTRATTO}</Typography>
                    <Button
                      variant="contained" className="!mx-auto"
                      onClick={() => navigate("/modalita/" + modalita.ID)}
                    >
                      Leggi di più
                    </Button>
                  </CardContent>
                </Card>
              ))
            }
          </div>
        }
      </Box>
    </Container>
  )
}

export default Modalita
