import { callProcessPath } from "../../utils/constants"

const call = async(data) => {
  const url = callProcessPath + "?format=JSON&username=WEBPUBLIC&password=WEBPUBLIC&dbname=dev&language=&processid=app_userregistration"

  let res = await fetch(url, {
    method: "POST",
    headers: {
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify(data)
  })

  res = await res.json()

  return res
}

const simpleReducer = (state, { key, value }) => {
  state[key] = value
  return ({ ...state })
}

export {
  call,
  simpleReducer
}
