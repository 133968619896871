import Chart from "react-chartjs-2"
import { Typography } from "@mui/material"
import { primaryColor } from "../../utils/constants"

const Graph = ({ className, data: rawData }) => {
  const options = {
    indexAxis: "x",
    scales: {
      y: {
        ticks: {
          crossAlign: "far"
        }
      }
    },
    orientation: "vertical",
    responsive: true,
    plugins: {
      title: {
        display: true,
        color: "white",
        position: "top",
        align: "left"
      }
    },
    elements: {
      bar: {
        borderColor: `rgb(${primaryColor.rgb})`,
        backgroundColor: `rgba(${primaryColor.rgb}, 0.5)`,
        borderRadius: 10
      },
      line: {
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false
      }
    }
  }

  const datasets = rawData.LATEST_MATCH_DATA.reduce((acc, elem) => {
    for (const [key, value] of Object.entries(elem)) {
      if (key === "ACCURACY") {
        continue
      }

      if (key in acc) {
        acc[key][0].data?.push(+rawData.AVG_DATA[key] || 0)
        acc[key][1].data?.push(value)
        continue
      }

      acc[key] = [
        {
          type: "line",
          label: `Media globale ${key}`,
          borderColor: "rgb(255, 99, 132)",
          borderWidth: 2,
          fill: false,
          data: [+rawData.AVG_DATA[key]]
        },
        {
          type: "bar",
          label: `${key} Giocatore`,
          data: [+value],
          borderColor: `rgb(${primaryColor.rgb})`,
          backgroundColor: `rgba(${primaryColor.rgb}, 0.5)`,
          fill: true,
          borderWidth: 2
        }
      ]
    }

    return acc

  }, {})

  const childs = []
  for (const [key, value] of Object.entries(datasets)) {
    childs.push(
      <div
        className="border-white border p-5 max-md:p-1 rounded-sm"
        key={key + "-stats-graph"}
      >
        <Typography
          variant="h6"
          className="statistic__value"
        >{key}</Typography>
        <Chart
          type="bar"
          options={options}
          data={{
            labels: (new Array(value[0].data.length)).fill("").map((e, i) => `${i + 1}`),
            datasets: value
          }}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      {childs}
    </div>
  )
}

export default Graph
