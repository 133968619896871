import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"

import { callProcess } from "../utils/utils"
import useAuthStore from "../hooks/authStore"

export default function RfidCmp() {
  const params = useParams()
  // const [rfid, setRfid] = useState(params.rfid)

  const navigate = useNavigate()

  const {
    username, password
  } = useAuthStore()

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&language=&processid=app_rfidcheck&rfid=" + params.rfid + ""
    callProcess({ username, password, processid: "app_rfidcheck", rfid: params.rfid })
      .then((res) => {
        // console.log(res)
        navigate("/" + res.redirectTo)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })
  }, [
    username,
    password,
    params.rfid,
    navigate
  ])

  return (
    <></>
  )
}
