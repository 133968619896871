import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Button, Container, TextField, Typography } from "@mui/material"

import { validateEmail } from "../utils/validators"
import toast from "react-hot-toast"
import useFetcher from "../hooks/useFetcher"

const ContactPage = () => {

  const navigate = useNavigate()

  const { callProcess } = useFetcher()

  const [formData, setFormData] = useState({
    NOME: "",
    COGNOME: "",
    EMAIL: "",
    DESCRIZIONE: ""
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async(event) => {
    event.preventDefault()

    if (!validateEmail(formData.EMAIL || "") || formData.NOME === "" || formData.COGNOME === "" || formData.DESCRIZIONE === "") {
      toast.error("Compila tutti i campi")
      return
    }

    toast.loading("Caricamento...")

    const res = await callProcess("POST", {
      processid: "app_contattami",
      body: formData
    })

    if (res.success) {
      toast.success("Messaggio inviato con successo")

      setFormData({
        NOME: "",
        COGNOME: "",
        EMAIL: "",
        DESCRIZIONE: ""
      })

      setTimeout(() => {
        navigate("/")
      }, 1500)

    } else {
      toast.error("Non è stato possibile inviare il messagio. Riprova piu tardi")
    }
  }

  return (
    <Container
      className="glassmorphism container--single !p-10"
    >
      <Typography variant="h4" className="text-cyan">
        Contatti
      </Typography>
      <form
        className="p-5 flex flex-col gap-3 mx-auto"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          margin="normal"
          label="Nome"
          name="NOME"
          value={formData.NOME}
          onChange={handleInputChange}
        />
        <TextField
          required
          margin="normal"
          label="Cognome"
          name="COGNOME"
          value={formData.COGNOME}
          onChange={handleInputChange}
        />
        <TextField
          required
          margin="normal"
          label="Email"
          name="EMAIL"
          type="email"
          value={formData.EMAIL}
          onChange={handleInputChange}
        />
        <TextField
          required
          margin="normal"
          multiline
          rows={4}
          label="Descrizione"
          name="DESCRIZIONE"
          value={formData.DESCRIZIONE}
          onChange={handleInputChange}
        />

        <hr className="w-2/3 mx-auto my-3"/>

        <Button type="submit" variant="outlined" color="primary" className="w-3/5 !mx-auto">
          Invia
        </Button>
      </form>
    </Container>
  )
}

export default ContactPage
