import { create } from "zustand"

import { persist, createJSONStorage } from "zustand/middleware"

const initialState = {
  userID: 3,
  userName: "",
  username: "WEBPUBLIC",
  password: "WEBPUBLIC",
  isLogged: false,
  userAvatar: "",
  userdata: [],
  language: "en"
}

const useAuthStore = create()(
  persist(
    (set) => ({
      ...initialState,
      setData: (data) => set({ ...data }),
      logout: () => set(initialState)
    }), {
      name: "authStore",
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export default useAuthStore
