import React, { useReducer } from "react"
import { TextField, Button, Typography } from "@mui/material"
import { validateEmail } from "../../utils/validators"
import useAuthStore from "../../hooks/authStore"
import toast from "react-hot-toast"

const formReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FIELD":
      delete state.errors[action.field]
      return {
        ...state,
        [action.field]: action.value,
        errors: { ...state.errors }
      }
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.errors
      }
    default:
      return state
  }
}

const ContactArenaForm = ({ className, email, id }) => {
  const { userdata } = useAuthStore()
  const [state, dispatch] = useReducer(formReducer, {
    emailReadonly: email, // email readonly
    name: "",
    secondEmail: userdata?.userdata?.EMAIL || "",
    description: "",
    errors: {}
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    const errors = {}
    if (!state.name) {
      errors.name = "Il nome è obbligatorio"
    }

    if (!state.secondEmail) {
      errors.secondEmail = "La mail è obbligatoria"
    }
    if (!validateEmail(state.secondEmail)) {
      errors.secondEmail = "La mail non è valida"
    }

    if (!state.description) {
      errors.description = "La descrizione è obbligatoria"
    }

    if (Object.keys(errors).length > 0) {
      dispatch({ type: "SET_ERRORS", errors })
      return
    }

    // eslint-disable-next-line no-console
    console.log("submitting", {
      name: state.name,
      secondEmail: state.secondEmail,
      description: state.description
    })

    toast.success("Messaggio inviato con successo")
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`space-y-4 p-6 max-w-md mx-auto flex flex-col ${className}`}
    >
      <TextField
        label="Email Readonly"
        value={state.emailReadonly}
        InputProps={{
          readOnly: true
        }}
        fullWidth
        variant="outlined"
        className="mb-4"
      />

      <TextField
        label="Nome"
        value={state.name}
        onChange={(e) =>
          dispatch({ type: "UPDATE_FIELD", field: "name", value: e.target.value })
        }
        fullWidth
        variant="outlined"
        error={!!state.errors.name}
        helperText={state.errors.name}
        className="mb-4"
      />

      <TextField
        label="Email"
        value={state.secondEmail}
        onChange={(e) =>
          dispatch({ type: "UPDATE_FIELD", field: "secondEmail", value: e.target.value })
        }
        type="email"
        fullWidth
        variant="outlined"
        error={!!state.errors.secondEmail}
        helperText={state.errors.secondEmail}
        className="mb-4"
      />

      <TextField
        label="Descrizione"
        value={state.description}
        onChange={(e) =>
          dispatch({ type: "UPDATE_FIELD", field: "description", value: e.target.value })
        }
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        error={!!state.errors.description}
        helperText={state.errors.description}
        className="mb-4"
      />

      <Button type="submit" variant="contained" color="primary" className="w-full">
        Invia
      </Button>

      {
        Object.keys(state.errors).length > 0 && (
          <Typography color="error" className="mt-2">
            Compila tutti i campi obbligatori.
          </Typography>
        )
      }
    </form>
  )
}

export default ContactArenaForm
