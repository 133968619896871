import { Box, Container, Typography } from "@mui/material"

const SignupComplete = () => {
  return (
    <Container className="container--single">
      <Box className="glassmorphism p-5 !pl-[3%]">
        <Typography variant="h4" gutterBottom className="text-cyan">
          Controlla la tua email per attivare l'account
        </Typography>
        <hr/>
        <Typography className="!my-[3%] text-white" variant="h5">
          Le istruzioni per attivare il tuo account sono state inviate alla tua email,
          controlla la tua casella di posta elettronica per completare la registrazione.
          <br/>
          Le credenziali di accesso saranno inviate al termine della procedura di attivazione
        </Typography>
      </Box>
    </Container>
  )
}

export default SignupComplete
