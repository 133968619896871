import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

const Head = ({ headCells, order, orderBy, onRequestSort, ...others }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead sx={{ padding: "2%" }} {...others}>
      <TableRow>
        {
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
              className="table__custom--cell"
            >
              {
                headCell.sortable ?
                  (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {
                        orderBy === headCell.id ?
                          <span className={order === "desc" ? "sorted descending" : "sorted ascending"}></span>
                          : <></>
                      }
                    </TableSortLabel>
                  ) :
                  (
                    headCell.label
                  )
              }
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  )
}

export default Head
