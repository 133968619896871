import { useCallback, useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import {
  Container, Divider, Avatar,
  Tabs, Tab, Box, Grid
} from "@mui/material"

import { callProcessPath, imageBasePath, dbName } from "../../utils/constants"
import { a11yProps } from "../../utils/utils"

import UserSettings from "../User/UserSettings"
import MatchesTable from "../User/MatchesTable"
import MatchReport from "../User/MatchReport"
import Statistics from "./Statistics"
import TabPanel from "../../components/TabPanel"

// import "../../styles/user.css"
// import StatsGraph from "../User/StatsGraph"
import useAuthStore from "../../hooks/authStore"
import toast from "react-hot-toast"

const Profile = () => {
  const params = useParams()

  const [searchParams] = useSearchParams()
  const [value, setValue] = useState(searchParams.get("action") === "edit" ? 2 : 0)
  const [currMatch, setCurrMatch] = useState(0)

  const {
    username, password
  } = useAuthStore()

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&playerID=" + params.playerID + "&language=&processid=app_userdata"

  const [data, setData] = useState([])

  const fetchData = useCallback(async() => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error("Data coud not be fetched!")
    } else {
      return response.json()
    }
  }, [url])

  useEffect(() => {
    fetchData()
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [fetchData])

  useEffect(() => {
    if (searchParams.get("action") === "edit") {
      toast.error("Perfavore compila i campi del tuo profilo")
    }

  }, [searchParams])

  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          sx={{ width: "100%", margin: "auto", padding: "3%", flexDirection: "row !important" }}
          style={{ gap: "2%", rowGap: "1vh" }}
        >
          <Grid
            item
            xs={12} md={4} lg={4}
            className="glassmorphism"
            sx={{ padding: "3%" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Avatar sx={{ width: "70px", height: "70px" }} src={imageBasePath + data.AVATAR}
                alt={data.AVATAR}/>
            </Box>
            <br/>
            <br/>
            <Divider/>
            <Tabs variant="fullWidth" value={value} onChange={(_, newValue) => setValue(newValue)}
              sx={{ display: "flex" }}>
              <Tab label="Radar" {...a11yProps(0)} />
              {data.useranagrafica ? <Tab label="Partite" {...a11yProps(1)}/> : <div></div>}
              {data.useranagrafica ? <Tab label="Modifica Profilo" {...a11yProps(2)}/> : <div></div>}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={7} lg={7} className="glassmorphism">
            <TabPanel secondHalf value={value} index={0} style={{ height: "100%", width: "100%" }}>
              <Statistics data={data}/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={1}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchesTable
                    activeMatchReport={(matchID) => {
                      setCurrMatch(matchID)
                      setValue(3)
                    }}
                  />
                  : <></>
              }
            </TabPanel>
            <TabPanel secondHalf value={value} index={2}>
              <UserSettings/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={3}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchReport currMatchID={currMatch}/>
                  : <></>
              }
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Profile
