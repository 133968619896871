import { Button, Card, CardContent, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const ArenaCard = ({ arena, ...others }) => {
  const {
    id,
    nome,
    citta,
    logo
  } = arena

  const navigate = useNavigate()

  return (
    <Card className="w-full rounded-lg shadow-cs cursor-pointer" onClick={() => navigate(`/arena/${id}`)} {...others}>
      <CardContent className="w-full flex flex-col">
        <div className="mb-2 flex flex-row flex-nowrap items-center gap-3">
          <img
            alt=""
            className="rounded-lg w-[7vw] h-[7vw] object-cover max-md:w-[20vw] max-md:h-[20vw]"
            src={logo ? logo : `https://placehold.co/600x400/000000/FFF?text=${nome.slice(0, 2).toUpperCase()}`}
          />
          <div className="flex flex-col">
            <Typography variant="h5" component="h5" className="!font-bold text-white">
              {nome}
            </Typography>
            <Typography variant="body1" component="p" className="!font-bold text-white">
              {citta}
            </Typography>
          </div>
        </div>

        <hr className="w-2/3 my-3 text-gray-600 bg-gray-600 mx-auto"/>

        <Button className="mx-auto max-w-2/3" onClick={() => navigate(`/arena/${id}`)} variant="contained">Visualizza
          dettagli
          arena</Button>
      </CardContent>
    </Card>
  )
}

export default ArenaCard
