import LightGallery from "lightgallery/react"
import lgZoom from "lightgallery/plugins/zoom"
import lgThumbnail from "lightgallery/plugins/thumbnail"

import "lightgallery/css/lightgallery.css"
import "lightgallery/css/lg-zoom.css"
import "lightgallery/css/lg-thumbnail.css"

import classnames from "classnames"

const Gallery = ({ data, className }) => {

  return (
    <div className={classnames("gallery__container", className)}>
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        elementClassNames="grid grid-cols-1 items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2"
      >
        {
          data
            .filter(item => item !== "data:image/ory;base64,")
            .map((item, index) => (
              <a href={item} key={index} className="mx-auto">
                <img className="gallery__image shadow-cs" src={item} alt=""/>
              </a>
            )
            )
        }
      </LightGallery>
    </div>
  )
}

export default Gallery
