import { Box, Typography } from "@mui/material"
import classnames from "classnames"

const TabPanel = ({ children, value, index, secondHalf, sx, className, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%", width: "100%" }}
      {...other}
    >
      {
        secondHalf ? (
          <Box className={classnames(className, ["w-full", "h-full"])} sx={sx}>
            {children}
          </Box>
        ) :
          value === index && (
            <Box className={classnames(className, ["w-full", "h-full"])} sx={sx}>
              <Typography>{children}</Typography>
            </Box>
          )
      }
    </div>
  )
}

export default TabPanel
