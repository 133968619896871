import { useEffect } from "react"
import { useParams } from "react-router-dom"

import {
  CircularProgress,
  Container, Box
} from "@mui/material"

import UserCard from "../../components/UserCard"
import { imageBasePath } from "../../utils/constants"
import useFetcher from "../../hooks/useFetcher"

const TopOfTheMonth = () => {
  const params = useParams()
  const { callProcess, data, isLoading } = useFetcher()

  useEffect(() => {
    callProcess("POST", {
      processid: "app_toprank",
      body: {
        filter: "currentMonth",
        arenaID: params.arenaID
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.arenaID])

  if (isLoading) {
    return (
      <Container className="container--single min-h-[50vh]">
        <Box className="glassmorphism flex items-center justify-center py-10 min-h-[50vh]">
          <CircularProgress className="circular-progress--centered"/>
        </Box>
      </Container>
    )
  }

  return (
    <Container
      // className="w-full !grid !grid-cols-2 !gap-3 max-md:!grid-cols-1"
      className="container--2-row"
    >
      {
        data?.dati?.length ?
          data.dati.map((toprank, index) => {
            const {
              name,
              user_id,
              IMMAGINE,
              avatar,
              arenaprovenienza,
              CT_ARENAPROVENIENZA,
              CT_RANK_TABLE,
              DESCRIPTION,
              score

            } = toprank

            return (
              <UserCard
                key={"arena-best-month-" + index + "-" + user_id}
                userdata={{
                  avatar: IMMAGINE ? imageBasePath + IMMAGINE : avatar,
                  username: name,
                  user_id,
                  arenaprovenienza,
                  arena_id: CT_ARENAPROVENIENZA,
                  rank_id: CT_RANK_TABLE,
                  rank: DESCRIPTION,
                  position: index + 1,
                  score
                }}
              />
            )
          })
          :
          <div className="text-center w-full col-span-3">
            <h2 className="text-2xl text-white">Nessun dato disponibile</h2>
          </div>
      }
    </Container>
  )
}

export default TopOfTheMonth
