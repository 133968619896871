import { useReducer } from "react"
import { backendUrl } from "../utils/constants"
import useAuthStore from "./authStore"

const useFetcher = () => {
  const [data, dispatchData] = useReducer(
    (state, { action, value }) => {
      switch (action) {
        case "data":
          return { ...state, data: value, isLoading: false }
        case "isLoading":
          return { ...state, isLoading: value }
        case "error":
          return { ...state, error: value, isLoading: false }
        default:
          return state
      }
    },
    {
      data: null,
      isLoading: false,
      error: null
    })

  const { username, password, userID } = useAuthStore()

  const callProcess = async(
    method = "GET",
    {
      processid = "",
      path = null,
      body = null,
      action = "process"
    },
    authenticated
  ) => {
    dispatchData({ action: "isLoading", value: true })

    const url = path ? path : `${backendUrl}`

    try {
      body = body ? body : {}
      body.action = action

      if (processid) {
        body.processid = processid
      }

      if (authenticated) {
        body.username = username
        body.password = password
        body.userID = userID
      }

      let response = await fetch(url, {
        method,
        headers: {
          ...(path ? {} : {
            "Content-Type": "application/json;charset=UTF-8",
            "X-Requested-With": "XMLHttpRequest"
          })
        },
        credentials: "same-origin",
        ...(method !== "GET" && body ? { body: JSON.stringify(body) } : {})
      })

      if (!response.ok) {
        // eslint-disable-next-line no-console
        console.error("response", response)
        throw new Error(response.statusText)
        // dispatchData({ action: "error", value: response })
      }

      response = await response.json()

      dispatchData({ action: "data", value: response })
      return ({ ...data, data: response })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      dispatchData({ action: "error", value: error })
    }

    dispatchData({ action: "isLoading", value: false })
  }

  return ({ callProcess, ...data })
}

export default useFetcher
