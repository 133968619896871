import { useState } from "react"
import { validateEmail } from "../utils/validators"

import { Container, Typography, Button, TextField } from "@mui/material"
import toast from "react-hot-toast"
import useFetcher from "../hooks/useFetcher"

const ResetPassword = () => {
  const [email, setEmail] = useState("")

  const { callProcess } = useFetcher()

  const handleSubmit = () => {

    if (!email || !validateEmail(email)) {
      toast.error("Inserire una email valida")
      return
    }

    toast.loading("Loading")

    callProcess("POST", {
      processid: "app_resetpasswordrequest",
      body: {
        EMAIL: email
      }
    })
      .then(response => {
        if (!response.fail) {
          toast.success("La password per il recupero password è stata inviata con successo alla mail indicata")
        } else {
          throw new Error(response.message)
        }
      })
      .catch(error => {
        toast.error(error.message || "Si è verificato un errore, riprova")
        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  return (
    <>
      <Container
        className="container--single !p-5"
      >
        <div
          className="glassmorphism flex flex-col gap-3 fit-content p-5"
        >
          <Typography marginLeft="2%" variant="h3" className="text-cyan">
            Recupero password
          </Typography>
          <form className="mx-auto flex flex-col gap-3 w-2/3">
            <TextField
              required
              id="email"
              label="Email"
              name="EMAIL"
              type="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              helperText="Inserire la email"
              error={false}
            />

            <hr className="w-2/3 mx-auto bg-cyan my-3"/>

            <Button
              className="w-4/5 !mx-auto" size="large" variant="outlined"
              onClick={handleSubmit}
            >
              Invia Mail
            </Button>
          </form>
        </div>
      </Container>
    </>
  )
}

export default ResetPassword
