import theme from "./theme/theme"
import { ThemeProvider } from "@mui/material/styles"

import { Routes, Route } from "react-router-dom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Toaster } from "react-hot-toast"

import Drawer from "./components/Drawer"
import Home from "./pages/Home"
import Login from "./pages/Login"
import Footer from "./components/Footer"
import TopRanking from "./pages/TopRanking/index"
import Modalita from "./pages/Modalita"
import DetailModalita from "./pages/Modalita/DetailModalita"
import Arene from "./pages/Arene/index"
import PageArene from "./pages/Arene/PageArene"
import PlayerProfilo from "./pages/User/index"
import PlayerProfiloV2 from "./pages/UserV2/index"
import ChangePassword from "./pages/User/ChangePassword"
import ResetPassword from "./pages/ResetPassword"
import SignupForm from "./pages/Signup/SignupForm"
import ContactPage from "./pages/ContactPage"
import HandleRefreshPassword from "./pages/User/HandleRefreshPassword"
import Document from "./pages/DocumentsPages/Document"

import { DOCUMENT_ID } from "./hooks/useDocument"
import Achievements from "./pages/Achievements"
import { useEffect, useState } from "react"

import "./styles/index.css"
import SignupComplete from "./pages/Signup/SignupComplete"
import RfidCmp from "./components/Rfid"
import MatchReport from "./pages/User/MatchReport"

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const [isPWA, setIsPWA] = useState(false)

  useEffect(() => {
    try {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        // eslint-disable-next-line no-console
        console.log("La PWA è stata installata")
        sessionStorage.setItem("install", "true")
        setIsPWA(true)
      } else {
        // eslint-disable-next-line no-console
        console.log("La PWA non è stata installata")
        sessionStorage.setItem("install", "false")
        setIsPWA(false)
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Errore nel rilevare se la PWA è stata installata")
    }
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div className="App relative">
          <Drawer/>
          <div className="min-h-screen grid place-items-center">
            <div className="row-start-1 self-center">

              <Routes>
                <Route path="" element={<Home/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/Home" element={<Home/>}/>
                <Route path="/TopRanking" element={<TopRanking/>}/>
                <Route path="/modalita" element={<Modalita/>}/>
                <Route path="/modalita/:pageID" element={<DetailModalita/>}/>
                {/* <Route path="/PageModalities/:pageID" element={<PageModalities/>}/>*/}
                <Route path="/arene" element={<Arene/>}/>
                <Route path="/arena/:arenaID" element={<PageArene/>}/>
                <Route path="/Login" element={<Login/>}/>
                <Route path="/confirm/:token" element={<HandleRefreshPassword/>}/>
                <Route path="/restyle/Achievements" element={<Achievements/>}/>
                {/* <Route path="/Registrazione" element={<SignupForm/>} /> */}
                <Route path="/Registrazione/:rfid" element={<SignupForm/>}/>
                <Route path="/SignupComplete" element={<SignupComplete/>}/>
                <Route path="/Profilo/:playerID" element={<PlayerProfilo/>}/>
                <Route path="/ProfiloV2/:playerID" element={<PlayerProfiloV2/>}/>
                <Route path="/Profilo/Match/:matchID" element={<MatchReport/>}/>
                {/* <Route path="/Rfid" element={<RfidCmp/>}/>*/}
                <Route path="/Rfid/:rfid" element={<RfidCmp/>}/>
                <Route path="/ModificaPassword" element={<ChangePassword/>}/>
                <Route path="/ResetPassword" element={<ResetPassword/>}/>
                <Route path="/ContactPage" element={<ContactPage/>}/>
                <Route path="/PrivacyPolicy" element={<Document documentId={DOCUMENT_ID.PRIVACY}/>}/>
                <Route path="/NoteLegali" element={<Document documentId={DOCUMENT_ID.NOTE}/>}/>
                <Route path="*" element={<Home/>}/>
              </Routes>
            </div>

            <div className="footer__container">
              <Footer className="glassmorphism"/>
            </div>
          </div>
          <Toaster
            position="bottom-right"
          />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
