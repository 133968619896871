import { useEffect } from "react"

import {
  Container, CircularProgress,
  TableContainer
} from "@mui/material"

import { dateFormatter } from "../../utils/utils"
import Table from "../../components/SortableTable/Table"
import useFetcher from "../../hooks/useFetcher"

const MatchesTable = ({ activeMatchReport }) => {
  const { callProcess, data } = useFetcher()

  useEffect(() => {
    callProcess("POST", { processid: "app_usermatchs" }, true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!data) {
    return (
      <Container className="p-0 w-full h-full !flex justify-center items-center">
        <CircularProgress className="circular-progress--centered"/>
      </Container>
    )
  }

  return (
    <Container className="p-0 w-full max-w-full flex">
      <TableContainer className="container--scrollable w-full">
        <Table
          activeMatchReport={activeMatchReport}
          rows={
            data.matchs.map((match) => ({
              gameMode: match.GAME_MODE,
              arena: match.ARENA_NAME,
              score: match.SCORES,
              date: dateFormatter(match.START_TIME),
              matchId: match.MATCH_ID
            }))
          }
        />
      </TableContainer>
    </Container>
  )
}

export default MatchesTable
