import { useEffect, useState } from "react"

import {
  CircularProgress, Divider, Container, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material"

import { styled } from "@mui/material/styles"
import { tableCellClasses } from "@mui/material/TableCell"

import useAuthStore from "../../hooks/authStore"
import useFetcher from "../../hooks/useFetcher"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#12121200",
    color: "#FFFFFFB3",
    textTransform: "capitalize"
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    color: "#1EDCFF"
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#FFFFFFB3"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0
  },
  "&.styled-row__self-report > *": {
    color: "#1EDCFF !important"
  }
}))

const MatchReport = ({ currMatchID }) => {
  const {
    username
  } = useAuthStore()

  const { callProcess, data: dataMatch } = useFetcher()
  const [foesScore, setFoesScore] = useState(0)

  useEffect(() => {
    if (Number.isInteger(currMatchID)) {
      return
    }

    callProcess("POST", {
      processid: "app_usermatchs",
      body: {
        matchID: currMatchID
      }
    }, true
    ).then(res => {
      if (!res?.data?.match_data) {
        return
      }
      setFoesScore(res.data.match_data.FOES.reduce((acc, { SCORES }) => acc + (+SCORES), 0))
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMatchID])

  if (!dataMatch) {
    return (
      <Container className="!p-5 text-center">
        <CircularProgress className="circular-progress--centered"/>
      </Container>
    )
  }

  return (
    <Container className="!p-5">
      <Typography variant="h3" color="primary">{dataMatch.match_data.NAME}</Typography>
      <Divider className="!mb-5 !mt-2 w-2/3 bg-cyan"/>

      <img
        className="mx-auto w-1/4 my-3"
        alt={dataMatch.match_data.GAME_MODE}
        src={
          dataMatch.match_data && dataMatch.match_data.GAME_MODE_LOGOURL ?
            dataMatch.match_data.GAME_MODE_LOGOURL : "https://via.placeholder.com/150"
        }
      />


      <div className="grid grid-cols-3 gap-3">
        {
          [
            { title: "Modalità", value: dataMatch.match_data.GAME_MODE },
            { title: "Arena", value: dataMatch.match_data.ARENA_NAME },
            { title: "Punteggio", value: dataMatch.match_data.SCORES },
            { title: "Data", value: dataMatch.match_data.HOUR + " - " + dataMatch.match_data.DATE }
          ].map(({ title, value }, index) => (
            <div key={`match-${title}-${index}`}>
              <Typography className="mb-2 text-gray-300">
                {title[0].toUpperCase() + title.slice(1)}
              </Typography>
              <p className="font-bold ml-[3%] block text-white">{value}</p>
            </div>
          ))
        }
      </div>

      <Divider className="!my-4 !mx-auto !w-2/3"/>


      <div className="grid grid-cols-3">
        {
          [
            { title: "rank", value: dataMatch.match_data.RANKING },
            { title: "precisione", value: dataMatch.match_data.ACCUARCY },
            { title: "colpi subiti", value: dataMatch.match_data.DEATH },
            { title: "basi conquistate", value: dataMatch.match_data.CAPTURES },
            { title: "colpi sparati", value: dataMatch.match_data.TOTSHOTS },
            { title: "colpi inflitti", value: dataMatch.match_data.KILLS },
            { title: "ricariche", value: dataMatch.match_data.RELOADS }
          ].map(({ title, value }, index) => (
            <div key={`match-${title}-${index}`}>
              <Typography className="mb-2 text-gray-300">
                {title[0].toUpperCase() + title.slice(1)}
              </Typography>
              <p className="font-bold ml-[3%] block text-white">{value}</p>
            </div>
          ))
        }
      </div>

      <Divider className="!my-4 !mx-auto !w-2/3"/>

      <div className="flex flex-col gap-5">
        <TableContainer component={Paper} sx={{ maxWidth: "80vw", marginX: "auto" }}>
          <Table sx={{ boxSizing: "content-box" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell size="medium">Team Alleato
                  <span
                    style={{ display: "block" }}>{dataMatch.match_data.ALLIES_TEAM_SCORES}</span></StyledTableCell>
                <StyledTableCell align="center">colpi subiti</StyledTableCell>
                <StyledTableCell align="center">colpi infliti</StyledTableCell>
                <StyledTableCell align="right">punteggio</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataMatch.match_data.ALLIES && dataMatch.match_data.ALLIES.map((allie, index) => {
                  return (
                    <StyledTableRow key={"ALLIES-" + index}
                      className={allie.NAME === username ? "styled-row__self-report" : ""}>
                      <StyledTableCell scope="row" sx={{ alignItems: "center" }}>{allie.NAME}</StyledTableCell>
                      <StyledTableCell align="center">{allie.DEATH}</StyledTableCell>
                      <StyledTableCell align="center">{allie.KILLS}</StyledTableCell>
                      <StyledTableCell align="right">{allie.SCORES}</StyledTableCell>
                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} sx={{ maxWidth: "80vw", marginX: "auto" }}>
          <Table sx={{ boxSizing: "content-box" }}>
            <TableHead>
              <TableRow>
                <StyledTableCell size="medium">Team Avversario<span
                  style={{ display: "block" }}>{foesScore}</span>
                </StyledTableCell>
                <StyledTableCell align="center">colpi subiti</StyledTableCell>
                <StyledTableCell align="center">colpi infliti</StyledTableCell>
                <StyledTableCell align="right">punteggi</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataMatch.match_data.FOES && dataMatch.match_data.FOES.map((foe, index) => {
                  return (
                    <StyledTableRow key={"FOES-" + index}>
                      <StyledTableCell scope="row" sx={{ alignItems: "center" }}>{foe.NAME}</StyledTableCell>
                      <StyledTableCell align="center">{foe.DEATH}</StyledTableCell>
                      <StyledTableCell align="center">{foe.KILLS}</StyledTableCell>
                      <StyledTableCell align="right">{foe.SCORES}</StyledTableCell>
                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  )
}

export default MatchReport
