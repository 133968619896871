import { useReducer } from "react"
import { simpleReducer } from "../Signup/signupUtils"

import { Container, Typography, Button, TextField } from "@mui/material"
import useAuthStore from "../../hooks/authStore"
import toast from "react-hot-toast"
import useFetcher from "../../hooks/useFetcher"

const ChangePassword = () => {

  const {
    username, userID, userAvatar: avatar, userName,
    setData
  } = useAuthStore()

  const [formInput, setFormInput] = useReducer(
    simpleReducer,
    {
      PASSWORD_SITE: "",
      PASSWORDCONF: ""
    }
  )

  const { callProcess } = useFetcher()

  const handleSubmit = () => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&language=&processid=app_userdataupdate"

    if (!formInput.PASSWORD_SITE || !formInput.PASSWORDCONF) {
      toast.error("Inserire password e conferma password")
      return
    }

    if (formInput.PASSWORD_SITE !== formInput.PASSWORDCONF) {
      toast.error("Password e conferma password devono coincidere")
      return
    }

    callProcess("POST", {
      processid: "app_userdataupdate",
      body: formInput
    }, true)
      .then(response => {
        if (response.success) {
          toast.success("La password è stata modificata correttamente")

          setData({
            userID,
            username,
            password: response.FormTesta.PASSWORD_SITE,
            isLogged: true,
            userName,
            userAvatar: avatar
          })
        } else {
          throw new Error(response.message)
        }

        // setTimeout(() => {
        //   navigate("/" + response.redirectTo)
        // }, 2000)

      })
      .catch(error => {
        toast.error(error.message || "Si è verificato un errore, riprova")

        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  const handleInput = ({ target }) => {
    const key = target.name
    const value = target.value
    setFormInput({ key, value })
  }

  return (
    <Container
      className="container--single glassmorphism !flex flex-col !justify-center gap-3"
    >
      <Typography marginLeft="2%" variant="h3" gutterBottom color="primary">
        Modifica Password
      </Typography>
      <form
        className="flex flex-col justify-center items-center gap-5 w-full h-full"
      >
        <TextField
          id="password"
          label="Password"
          name="PASSWORD_SITE"
          type="password"
          autoComplete="current-password"
          className="form__input"
          onChange={handleInput}
          helperText="Inserire la nuova password"
          error={false}
        />
        <TextField
          id="password-conf"
          label="Conferma Password"
          name="PASSWORDCONF"
          type="password"
          autoComplete="current-password"
          className="form__input"
          onChange={handleInput}
          helperText="Reinserisci la nuova password"
          error={false}
        />

        <hr className="w-2/3 mx-auto"/>

        <Button
          className="w-3/5 mx-auto"
          variant="outlined"
          onClick={handleSubmit}
        >
          MODIFICA PASSWORD
        </Button>
      </form>
    </Container>
  )
}

export default ChangePassword
