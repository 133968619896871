import { getRankingImage } from "../utils/utils"
import { Link, useNavigate } from "react-router-dom"
import { Card, Typography, CardContent } from "@mui/material"

const UserCard = ({ userdata }) => {
  const {
    username, user_id, arenaprovenienza, arena_id, rank_id, rank, avatar, position = null, score
  } = userdata
  const navigate = useNavigate()

  return (
    <Card className="w-full rounded-lg shadow-cs cursor-pointer max-md:min-content">
      <CardContent className="cursor-pointer" onClick={() => navigate(`/Profilo/${user_id}`)}>
        <div className="flex !flex-row flex-nowrap">
          <div className="mb-2 flex !flex-row flex-nowrap items-center gap-3">
            <img
              alt=""
              className="rounded-lg w-[7vw] h-[7vw] object-cover max-md:w-[20vw] max-md:h-[20vw]"
              src={avatar ? avatar : `https://placehold.co/600x400/000000/FFF?text=${username.slice(0, 2).toUpperCase()}`}/>
          </div>
          <div className="grid grid-cols-1 items-center ml-3">
            <Typography variant="h5" component="h5" className="!font-bold text-white truncate">
              {position ? position + "° - " : ""}{username}
            </Typography>
            <hr className="w-2/3 my-2 text-gray-600 bg-gray-600 mx-auto"/>
            <div className="flex flex-row flex-nowrap items-center">
              <img className="w-10 mr-2" src={require(`../assets/ranking-icons/${getRankingImage(rank_id)}`)} alt=""/>
              <Typography variant="body2" color="text.secondary">
                {rank}
                <br/>
                Punteggio: <span className="font-bold" style={{ placeSelf: "flex-end" }}>{score}</span>
              </Typography>
            </div>
            <Typography variant="body2" color="text.secondary" className="mt-2">
              {
                arenaprovenienza !== "NP" ?
                  <Link to={`/arena/${arena_id}`} variant="body2">Arena: <span
                    className="italic">{arenaprovenienza}</span></Link> :
                  <></>
              }
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default UserCard
