import { Card, Typography } from "@mui/material"
import useFetcher from "../../hooks/useFetcher"
import { useEffect } from "react"

const News = ({ arenaID }) => {
  const { callProcess, data } = useFetcher()

  useEffect(() => {
    callProcess("POST", {
      processid: "app_requestnews",
      body: {
        arenaID
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arenaID])

  return (
    <div className="text-white p-10 max-md:p-5">
      <Typography variant="h3">News</Typography>
      <hr className="bg-white my-3 mb-5"/>
      {
        data?.news.length ?
          data?.news?.map((news, index) => (
            <Card key={index} className="mb-5 p-10 max-md:p-3 shadow-cs">
              <Typography variant="h5" className="mb-3">{news.TITLE}</Typography>
              <Typography variant="body1">{news.BODY}</Typography>
              {
                news.IMAGE &&
                <img
                  src={news.IMAGE}
                  alt={news.TITLE}
                  className="my-3 w-2/3 mx-auto max-md:w-full rounded"
                />
              }
            </Card>
          ))
          :
          <Typography variant="h5" className="my-4">Al momento non ci sono news</Typography>
      }
    </div>
  )
}

export default News
