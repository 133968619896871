import { useEffect } from "react"
import { useParams } from "react-router-dom"

import {
  CircularProgress,
  Container
} from "@mui/material"

import UserCard from "../../components/UserCard"
import useFetcher from "../../hooks/useFetcher"
import { imageBasePath } from "../../utils/constants"

const TopRankArena = () => {
  const params = useParams()

  const { data, callProcess, isLoading } = useFetcher()

  useEffect(() => {
    callProcess("POST", {
      processid: "app_toprank",
      body: {
        arenaID: params.arenaID
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.arenaID])

  if (isLoading) {
    return (
      <Container className="!flex w-full justify-center items-center h-full overflow-hidden">
        <CircularProgress className="circular-progress--centered mx-auto"/>
      </Container>
    )
  }
  return (
    <Container
      // className="w-full !grid !grid-cols-2 !gap-3 max-md:!grid-cols-1"
      className="container--2-row"
    >
      {
        data?.dati && data?.dati.map((toprank, index) => {
          const {
            name,
            user_id,
            avatar,
            IMMAGINE,
            arenaprovenienza,
            CT_ARENAPROVENIENZA,
            CT_RANK_TABLE,
            DESCRIPTION,
            score
          } = toprank

          return (
            <UserCard
              key={"arena-best-month-" + index + "-" + user_id}
              userdata={{
                avatar: IMMAGINE ? imageBasePath + IMMAGINE : avatar,
                username: name,
                user_id,
                arenaprovenienza,
                arena_id: CT_ARENAPROVENIENZA,
                rank_id: CT_RANK_TABLE,
                rank: DESCRIPTION,
                position: index + 1,
                score
              }}
            />
          )
        })
      }
    </Container>
  )
}

export default TopRankArena
