import { Typography, Modal } from "@mui/material"
import Document from "../DocumentsPages/Document"

import { DOCUMENT_ID } from "../../hooks/useDocument"

const DocumentModal = ({ open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <div
        className="!w-[80vw] modal__content"
      >
        <Typography variant="h6" component="h2" className="text-cyan">
          Scarico responsabilità
        </Typography>
        <div className="mt-3">
          <Document isGlassMorphism={false} style={{ width: "100%" }} documentId={DOCUMENT_ID.SCARICORESPONSABILITA}/>
        </div>
      </div>
    </Modal>
  )
}

export default DocumentModal


