import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import {
  Avatar, Container, Divider,
  Tab, Tabs, Box, Typography, Button, CircularProgress
} from "@mui/material"

import MainMap from "../../utils/gmap"
import Gallery from "../../components/Gallery"
import TabPanel from "../../components/TabPanel"
import RankTabPanel from "./RankTabPanel"

import { a11yProps } from "../../utils/utils"
import useFetcher from "../../hooks/useFetcher"
import News from "./News"
import ContactArena from "../../components/ContactArena"

const PageArene = () => {
  const params = useParams()
  const { callProcess, data, isLoading } = useFetcher()
  const [value, setValue] = useState(0)

  useEffect(() => {
    callProcess("POST", {
      processid: "app_arenas",
      body: {
        ID: params.arenaID
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.arenaID])

  if (!data?.arena || isLoading) {
    return (
      <Container className="container--single min-h-[50vh]">
        <Box className="glassmorphism flex items-center justify-center py-10 min-h-[50vh]">
          <CircularProgress className="circular-progress--centered"/>
        </Box>
      </Container>
    )
  }

  return (
    <div
      className="arene__detail-container"
    >
      <div
        className="glassmorphism col-span-1 p-3 max-md:col-span-2"
      >
        <Avatar
          variant="square"
          src={data.arena.LOGOENCODED}
          alt={data.arena.LOGO}
          className="rounded-lg mx-auto my-3 max-md:max-w-[50%]"
          sx={{ width: "50%", height: "auto" }}
        />
        <div className="grid grid-flow-col justify-evenly mt-4">
          <Avatar
            src={require("../../assets/facebook.png")}
            onClick={() => window.open("https://www.facebook.com/" + data.arena.FACEBOOK + "", "_blank")}
            sx={{ cursor: "pointer", width: "20px", height: "20px" }}
            variant="square"
            className="w-full"
          />
          <Avatar
            src={require("../../assets/instagram.png")}
            onClick={() => window.open("https://www.instagram.com/" + data.arena.INSTAGRAM + "", "_blank")}
            sx={{ cursor: "pointer", width: "20px", height: "20px" }}
            variant="square"
            className="w-full"
          />
          {
            data.arena.lat && data.arena.lng ?
              <Avatar
                src={require("../../assets/maps.png")}
                onClick={() => setValue(1)}
                sx={{ cursor: "pointer", width: "20px", height: "20px" }}
                variant="square"
                className="w-full"
              />
              : <></>
          }
        </div>
        <div className="flex flex-col p-5 gap-2">
          <Typography variant="h4" color="primary" className="!mb-3">{data.arena.DESCRIZIONE}</Typography>
          <a
            style={{ color: "white" }}
            href={"tel:" + data.arena.TELEFONO}>Tel: {data.arena.TELEFONO}</a>
          {
            data.arena.WHATSAPP &&
            <a style={{ color: "white" }}
              href={"https://wa.me/" + data.arena.WHATSAPP}>Whatsapp: {data.arena.WHATSAPP}</a>
          }
          <a style={{ color: "white" }} href={"mailto:" + data.arena.EMAIL}>{data.arena.EMAIL}</a>
          <a
            style={{ color: "white" }}
            href={`https://www.google.com/maps?q=${data.arena.INDIRIZZO}, ${data.arena.PROVINCIA}`}
            target="__blank"
          >
            Indirizzo: {data.arena.INDIRIZZO}, {data.arena.PROVINCIA}
          </a>
          {
            data.arena.SITOWEB
            && (
              <Button
                className="!mt-3"
                onClick={() => {
                  window.open(data.arena.SITOWEB, "_blank")
                }}
              >
                Link al sito
              </Button>
            )
          }

          {/* {*/}
          {/*  data.arena.EMAIL &&*/}
          {/*  <ContactArena*/}
          {/*    className="my-4 mx-auto p-3 w-2/3 self-center" arenaID={params.arenaID} arenaName={data.arena.DESCRIZIONE}*/}
          {/*    arenaEmail={data.arena.EMAIL}*/}
          {/*  />*/}
          {/* }*/}
        </div>

        <Divider className="my-3"/>

        <Tabs variant="fullWidth" value={value} onChange={(_, newValue) => setValue(newValue)} sx={{ display: "flex" }}>
          <Tab label="Rank Arena" {...a11yProps(0)}/>
          <Tab label="Mappa" {...a11yProps(1)}
            style={{ display: data.arena.lat && data.arena.lng ? "block" : "none" }}/>
          <Tab label="Galleria" {...a11yProps(2)}/>
          <Tab label="News" {...a11yProps(3)}/>
        </Tabs>
      </div>

      <div className="glassmorphism col-span-2 second-half--container h-[80vh] p-3">
        <Box className="h-full overflow-scroll">
          <TabPanel secondHalf value={value} index={0} sx={{ padding: 0 }} style={{ padding: 0 }}>
            <RankTabPanel data={data.arena}/>
          </TabPanel>
          <TabPanel secondHalf value={value} index={1} className="!h-[80vh]">
            {
              data.arena.lat && data.arena.lng &&
              <MainMap
                className="w-full rounded-lg "
                pins={[
                  {
                    lng: +data.arena.lng,
                    lat: +data.arena.lat,
                    logo: data.arena.LOGOENCODED,
                    text: data.arena.DESCRIZIONE
                  }
                ]}
                center={{ lat: +data.arena.lat, lng: +data.arena.lng }}
              />
            }
          </TabPanel>
          <TabPanel secondHalf value={value} index={2}>
            {
              data.arena.GALLERYENCODED ?
                <Gallery className="w-full h-full" data={data.arena.GALLERYENCODED}/>
                :
                <div className="flex justify-center h-full items-center">
                  <Typography variant="h4" color="primary" className="!mb-3">Nessuna immagine</Typography>
                </div>
            }
          </TabPanel>
          <TabPanel secondHalf value={value} index={3}>
            <News arenaID={params.arenaID}/>
          </TabPanel>
        </Box>
      </div>
    </div>
  )
}

export default PageArene
