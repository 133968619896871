import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import {
  Container, Divider, Avatar,
  Tabs, Tab, Box, CircularProgress, Typography
} from "@mui/material"

import { imageBasePath } from "../../utils/constants"
import { a11yProps, dateFormatter, getRankingImage } from "../../utils/utils"

import UserSettings from "./UserSettings"
import MatchesTable from "./MatchesTable"
import MatchReport from "./MatchReport"
import TabPanel from "../../components/TabPanel"

import useAuthStore from "../../hooks/authStore"
import toast from "react-hot-toast"
import useFetcher from "../../hooks/useFetcher"
import Statistics from "./Statistics"

const Profile = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [value, setValue] = useState(searchParams.get("action") === "edit" ? 2 : 0)
  const [currMatch, setCurrMatch] = useState(0)
  const { data, callProcess } = useFetcher()
  const navigate = useNavigate()

  const {
    isLogged, setData
  } = useAuthStore()

  // const [data, setData] = useState(null)
  useEffect(() => {
    callProcess("POST", {
      processid: "app_userdata",
      body: {
        playerID: params.playerID
      }
    }, true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data && (data.useranagrafica || data.userdata)) {
      setData({
        userdata: data
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (searchParams.get("action") === "edit") {
      toast.error("Perfavore compila i campi del tuo profilo")
    }

  }, [searchParams])

  if (!data) {
    return (
      <Container className="container--single min-h-[50vh]">
        <Box className="glassmorphism flex items-center justify-center py-10 min-h-[50vh]">
          <CircularProgress className="circular-progress--centered"/>
        </Box>
      </Container>
    )
  }

  return (
    <div
      className="items-stretch justify-center w-full m-auto !grid grid-cols-1 md:grid-cols-3 gap-3 p-3 mt-10"
    >
      <div
        className="glassmorphism col-span-1 p-3 max-md:col-span-2"
      >
        <Avatar
          variant="square"
          src={imageBasePath + data.AVATAR} alt={data.AVATAR}
          className="rounded-lg mx-auto my-3 max-md:max-w-[50%]"
          sx={{ width: "30%", height: "auto" }}
        />

        <div className="flex flex-col lg:flex-row justify-center items-center gap-3">
          <img
            className="justify-self-end"
            style={{ width: "30px", height: "30px" }}
            src={(require(`../../assets/ranking-icons/${getRankingImage(data?.useranagrafica?.CT_RANK_TABLE)}`))}
            alt=""
          />
          <div>
            <Typography color="primary" variant="h4">{data.DISPLAY_NAME} </Typography>
            <Typography className="text-white my-3" variant="h5">{data.RANK}</Typography>
          </div>
        </div>

        <Divider className="!my-3"/>

        <div className="w-full">
          <Typography variant="h5" className="!my-3 text-white">Statistiche</Typography>
          <div className="text-white">
            {/* <p className="text-gray-300">Punteggio Totale:<span*/}
            {/*  className="text-white"> {data.global_user_games_data.global_score}</span></p>*/}
            {/* <p className="text-gray-300">Global Rank:<span*/}
            {/*  className="text-white"> {data.global_user_games_data.global_rank}</span></p>*/}
            {
              data?.useranagrafica && (
                <p className="text-gray-300">
                  Punti mancanti per salire di rank:
                  <span
                    className="text-white"> {(+data?.NEXT_RANK?.MINIMUM_SCORE) - (+data?.useranagrafica?.HONOR_SCORE)}</span>
                </p>
              )
            }
            <p className="text-gray-300">
              Partite giocate:
              <span
                className="text-white"> {data.global_user_games_data.global_matchs_num}</span></p>
            <p className="text-gray-300">Obbiettivi conquistati:
              <span
                className="text-white"> {data.global_user_games_data.global_captures}</span>
            </p>
            <p className="text-gray-300">Colpi inflitti:
              <span
                className="text-white"> {data.global_user_games_data.global_kills}</span>
            </p>
            <p className="text-gray-300">Vittorie:
              <span
                className="text-white"> {data.MATCHS_WINS ? data.MATCHS_WINS : 0}</span>
            </p>
            <p className="text-gray-300">Sconfitte:
              <span
                className="text-white"> {data.MATCHS_DEFEATS ? data.MATCHS_DEFEATS : 0}</span>
            </p>
            <p className="text-gray-300">Modalita di gioco preferita:
              <span
                className="text-white"> {data.PREFERRED_GAME_MODE}</span>
            </p>
            <p className="text-gray-300">Data di registrazione:
              <span
                className="text-white"> {dateFormatter(data.USER_REGISTERED)}</span>
            </p>
            <p className="text-gray-300">Arena più frequentata:
              <span
                className="text-white"
                onClick={() => navigate(`/arena/${data.ARENA_ORIGIN_ID}`)}> {data.ARENA_ORIGIN_NAME}</span>
            </p>
          </div>

          <Divider className="!my-3 !w-2/3 !mx-auto"/>

          {
            isLogged && data.useranagrafica ?
              <Tabs className="flex w-full" value={value} onChange={(_, newValue) => setValue(newValue)}>
                <Tab label="Radar" {...a11yProps(0)} sx={{ display: "none" }}/>
                <Tab label="Partite" {...a11yProps(1)}/>
                <Tab label="Modifica Profilo" {...a11yProps(2)}/>
              </Tabs>
              : <></>
          }
        </div>
      </div>

      <div className="glassmorphism col-span-2 second-half--container !w-full p-3">
        <TabPanel secondHalf value={value} index={0}
          className="w-full h-full overflow-scroll overflow-x-hidden flex flex-col items-center">
          {
            data && data.global_user_games_data && data.global_average_data ?
              <>
                <Statistics data={data}/>
              </>
              :
              <CircularProgress className="circular-progress--centered"/>
          }
        </TabPanel>
        {
          isLogged && data.useranagrafica ? (
            <>
              <TabPanel secondHalf value={value} index={1} className="w-full h-full overflow-scroll">
                <MatchesTable
                  activeMatchReport={(matchID) => {
                    setCurrMatch(matchID)
                    setValue(3)
                  }}
                />
              </TabPanel>
              <TabPanel secondHalf value={value} index={2} className="w-full h-full overflow-scroll">
                <UserSettings/>
              </TabPanel>
              <TabPanel secondHalf value={value} index={3} className="w-full h-full overflow-scroll">
                <MatchReport currMatchID={currMatch}/>
              </TabPanel>
            </>
          ) : <></>
        }
      </div>
    </div>
  )
}

export default Profile
