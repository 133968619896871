import { useEffect } from "react"
import {
  Container
} from "@mui/material"

import { imageBasePath } from "../../utils/constants"
import UserCard from "../../components/UserCard"
import useFetcher from "../../hooks/useFetcher"

const BestOfTheMonth = () => {
  const { callProcess, data } = useFetcher()

  useEffect(() => {
    callProcess("POST", {
      processid: "app_toprank",
      body: {
        filter: "currentMonth"
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container className="p-0 w-full">
      <div className="container--2-row">
        {

          data && data?.dati.length ?
            data?.dati.map((bestOfTheMonth, index) => {
              const {
                name,
                user_id,
                IMMAGINE,
                avatar,
                arenaprovenienza,
                CT_ARENAPROVENIENZA,
                CT_RANK_TABLE,
                DESCRIPTION,
                score
              } = bestOfTheMonth

              return (
                <UserCard
                  key={"best-month-" + index + "-" + user_id}
                  userdata={{
                    avatar: IMMAGINE ? imageBasePath + IMMAGINE : avatar,
                    username: name,
                    user_id,
                    arenaprovenienza,
                    arena_id: CT_ARENAPROVENIENZA,
                    rank_id: CT_RANK_TABLE,
                    rank: DESCRIPTION,
                    position: index + 1,
                    score
                  }}
                />
              )
            })
            :
            <div className="text-center w-full col-span-3">
              <h2 className="text-2xl text-white">Nessun dato disponibile</h2>
            </div>
        }

      </div>
    </Container>
  )
}

export default BestOfTheMonth
