import { Checkbox, TextField, Typography } from "@mui/material"
import DocumentModal from "./DocumentModal"
import { useState } from "react"

const MinorenneForm = ({ show, isErrorInput }) => {
  const [open, setOpen] = useState(false)

  if (!show) {
    return <></>
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom className="text-white !ml-[3%]">
        Dati Maggiorenne Rappresentante
      </Typography>

      <div className="flex flex-col gap-3">
        <TextField
          sx={{ width: "80%", margin: "auto" }}
          id="mag-nome"
          label="Nome"
          name="MAGGIORENENOME"
          error={isErrorInput.MAGGIORENENOME}
        />
        <TextField
          sx={{ width: "80%", margin: "auto" }}
          id="mag-cognome"
          label="Cognome"
          name="MAGGIORENECOGNOME"
          error={isErrorInput.MAGGIORENECOGNOME}
        />
        <TextField
          sx={{ width: "80%", margin: "auto" }}
          id="mag-cf"
          label="Codice Fiscale"
          name="MAGGIORENECF"
          error={isErrorInput.MAGGIORENECF}
        />
      </div>

      <div>
        <Checkbox
          required
          name="SCARICORESPONSABILITA"
        />
        <label className="text-white" onClick={() => setOpen(true)}>Scarico di responsabilità minori</label>
        <DocumentModal
          open={open}
          handleClose={() => {
            setOpen(false)
          }}
        />
      </div>
    </div>
  )
}

export default MinorenneForm
