const calculateCenter = (pins) => {
  if (pins.length === 0) {
    return { lat: 0, lng: 0 }
  } else if (pins.length === 1) {
    return {
      lat: pins[0].lat,
      lng: pins[0].lng
    }
  }

  const total = pins.reduce((acc, pin) => {
    return {
      lat: acc.lat + pin.lat,
      lng: acc.lng + pin.lng
    }
  }, { lat: 0, lng: 0 })

  return {
    lat: total.lat / pins.length,
    lng: total.lng / pins.length
  }
}

const mapDataReducer = (state, newState) => {
  // eslint-disable-next-line
  let { action, value } = newState
  let newCenter = state.center

  if (action === "pins") {
    value = [...state.pins, value]
    newCenter = calculateCenter(value)
  }

  return ({
    ...state,
    [action]: value,
    center: newCenter
  })
}
export {
  calculateCenter,
  mapDataReducer
}
