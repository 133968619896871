import { useEffect, useReducer } from "react"

import {
  Container, CircularProgress, Box
} from "@mui/material"

import ArenaCard from "../../components/ArenaCard"
import Map from "../../utils/gmap"
import useFetcher from "../../hooks/useFetcher"
import { mapDataReducer } from "./utils"

const Arene = () => {
  const { data, isLoading, callProcess } = useFetcher()

  const [mapData, setMapData] = useReducer(mapDataReducer, {
    pins: [],
    center: { lat: 0, lng: 0 },
    zoom: 6
  })

  useEffect(() => {
    callProcess("POST", {
      processid: "app_arenas"
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLoading || !data) {
      return
    }

    data.arenas.forEach((arena) => {
      const { COORDINATE, DESCRIZIONE: nome, LOGOENCODED: logo } = arena

      if (!COORDINATE) {
        return
      }

      const [lat, lng] = COORDINATE.split(",")

      setMapData({
        action: "pins",
        value: {
          lat: +(lat.trim()),
          lng: +(lng.trim()),
          logo,
          text: nome,
          url: `/arena/${arena.ID}`
        }
      })
    })
  }, [data, isLoading])

  if (isLoading) {
    return (
      <Container className="container--single min-h-[50vh]">
        <Box className="glassmorphism flex items-center justify-center py-10 min-h-[50vh]">
          <CircularProgress className="circular-progress--centered"/>
        </Box>
      </Container>
    )
  }

  return (
    <Container className="arene__container container--3-row">
      <Box
        className="arene__box min-[760px]:table__container--scrollable-in-view glassmorphism max-md:order-last"
        sx={{ flexFlow: "wrap" }}
      >
        {
          data?.arenas && data.arenas.map((arena, index) => {
            const {
              ID: id,
              DESCRIZIONE: nome,
              CITTA: citta,
              SITOWEB: sito,
              LOGOENCODED: logo,
              EMAIL: email
            } = arena

            // const indirizzo = `${INDIRIZZO}, ${CITTA} - ${PROVINCIA.toUpperCase()}`

            return (
              <ArenaCard
                key={`arena-${index}`}
                arena={{
                  id,
                  nome,
                  sito,
                  logo,
                  email,
                  citta
                }}
              />
            )
          })
        }
      </Box>
      <Box
        className="col-span-2 glassmorphism w-full p-10 max-md:min-h-[40vh] max-md:order-1">
        <Map
          className="w-full"
          pins={mapData.pins}
          zoom={mapData.zoom}
          center={mapData.center}
        />
      </Box>
    </Container>
  )
}

export default Arene

