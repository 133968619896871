import dayjs from "dayjs"

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import "dayjs/locale/en-gb"
import { TextField } from "@mui/material"
import { useState } from "react"

const CustomDatePicker = ({ onError, className, onChange }) => {
  const [value, setValue] = useState(dayjs(new Date()))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        label="Data di nascita"
        onChange={(newValue) => {
          setValue(dayjs(newValue))
          onChange(dayjs(newValue).format("DD-MM-YYYY"))
        }}

        renderInput={(params) => <TextField {...params} />}
        value={value}
        onError={onError}
        className={className}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
