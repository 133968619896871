import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { TextField, Button, Container, Typography } from "@mui/material"

import toast from "react-hot-toast"
import useAuthStore from "../hooks/authStore"
import useFetcher from "../hooks/useFetcher"

const Login = () => {
  const { setData } = useAuthStore()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { callProcess } = useFetcher()

  const navigate = useNavigate()

  const setLogin = async() => {
    if ((username === "") || (password === "")) {
      toast.error("Inserire username o password")
    } else {
      // const url = `${apiBasePath}includes/io/Login.php?format=JSONP&username=${username}&password=${password}&dbname=${dbName}&language=en`

      const { data: loginData, loginError } = await callProcess("POST",
        {
          action: "login",
          body: {
            username,
            password
          }
        })

      if (loginError) {
        toast.error("Errore: " + loginError.stack)
      }

      if (loginData.failure === true) {
        toast.error("Credenziali errate, riprovare")
        return
      }

      await setData({ username, password })

      const {
        data: userData,
        error: userDataError
      } = await callProcess("POST", {
        processid: "app_userdata",
        body: { playerID: loginData.UserAnagraficaId }
      })

      if (userDataError) {
        toast.error("Errore: " + userDataError.stack)
      }

      if (userData.success === false) {
        toast.error(userData.message)
      }

      setData({
        userID: loginData.UserAnagraficaId,
        userName: loginData.UserName,
        userAvatar: userData.AVATAR,
        userdata: userData,
        username,
        password,
        isLogged: true
      })

      toast.success("Benvenuto " + loginData.UserName)

      if (!userData.AVATAR) {
        navigate("/Profilo/" + loginData.UserAnagraficaId + "?action=edit")
      } else {
        navigate("/Home")
      }
    }
  }

  return (
    <Container
      className="container--single !p-5"
    >
      <div
        className="glassmorphism flex flex-col gap-3 fit-content p-5"
      >
        <Typography marginLeft="2%" variant="h3" gutterBottom className="text-cyan">
          Login
        </Typography>
        <form className="mx-auto flex flex-col gap-3 w-2/3">
          <TextField
            required
            label="Username"
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
          <TextField
            required
            label="Password"
            type={"password"}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <hr className="w-2/3 mx-auto bg-cyan my-3"/>
          <Button className="w-4/5 !mx-auto" size="large" variant="outlined" onClick={setLogin}> Login </Button>

          <Button
            variant="text"
            className="!underline w-2/3 !mx-auto"
            component={Link}
            to={"/ResetPassword"}
          >
            Hai dimenticato la password?
          </Button>
        </form>
      </div>
    </Container>
  )
}

export default Login
