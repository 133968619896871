import { useReducer, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Typography, Button, Container, TextField, Checkbox } from "@mui/material"

import { validateCF, validateEmail } from "../../utils/validators"
import { call, simpleReducer } from "./signupUtils"
import DatePicker from "../../components/DatePicker"
import toast from "react-hot-toast"
import MinorenneForm from "./MinorenneForm"

const SignupForm = () => {
  const params = useParams()
  const navigate = useNavigate()
  const formRef = useRef(null)

  const [birthDate, setBirthDate] = useReducer(simpleReducer, { date: null, isMinorenne: false })

  const [isErrorInput, dispatchIsErrorInput] = useReducer((...args) => {
    return simpleReducer(...args)
  }, {
    NOME: false,
    COGNOME: false,
    DISPLAYNAME: false,
    PASSWORD_SITE: false,
    PASSWORDCONF: false,
    EMAIL: false,
    RFID: false,
    PRIVACY: false,
    MAGGIORENENOME: false,
    MAGGIORENECOGNOME: false,
    MAGGIORENECF: false,
    SCARICORESPONSABILITA: false
  })

  const handleSubmit = () => {
    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)

    if (!birthDate.date) {
      toast.error("Inserisci la data di nascita")
      return
    }

    data.DATANASCITA = birthDate.date

    if (!("PRIVACY" in data)) {
      dispatchIsErrorInput({ key: "PRIVACY", value: true })
    }

    if (!("SCARICORESPONSABILITA" in data) && birthDate.isMinorenne) {
      dispatchIsErrorInput({ key: "SCARICORESPONSABILITA", value: true })
    }

    for (const key in data) {
      const value = data[key]

      if (!birthDate.isMinorenne && (["MAGGIORENENOME", "MAGGIORENECOGNOME", "MAGGIORENECF", "SCARICORESPONSABILITA"].includes(key))) {
        continue
      }

      if ((!value || value.length <= 2) && !["PRIVACY", "SCARICORESPONSABILITA", "RFID"].includes(key)) {
        dispatchIsErrorInput({ key, value: true })
        continue
      }

      let isInvalid = false

      switch (key) {
        case "EMAIL": {
          isInvalid = !validateEmail(value)
          break
        }

        case "PASSWORD_SITE":
        case "PASSWORDCONF": {
          if (value !== data["PASSWORD_SITE"] || value !== data["PASSWORDCONF"]) {
            isInvalid = true
            dispatchIsErrorInput({ key: "PASSWORD_SITE", value: true })
          }

          break
        }

        case "MAGGIORENECF": {
          isInvalid = !validateCF(value, data["MAGGIORENENOME"], data["MAGGIORENECOGNOME"], new Date(data["DATANASCITA"]))
          break
        }

        case "PRIVACY": {
          if (value !== "on") {
            isInvalid = true
          }

          break
        }

        case "SCARICORESPONSABILITA": {
          if (birthDate.isMinorenne && value !== "on") {
            isInvalid = true
          }

          break
        }

        case "RFID": {
          if (isNaN(+value)) {
            isInvalid = true
          }

          break
        }

        default:
          break
      }

      dispatchIsErrorInput({ key, value: isInvalid })
    }

    if (Object.values(isErrorInput).filter(item => item).length) {
      // eslint-disable-next-line no-console
      console.error("Invalid form", {
        data,
        isErrorInput,
        cond: Object.values(isErrorInput).filter(item => item).length
      })

      toast.error("Inserisci tutti i campi")
      return
    }

    call(data)
      .then(res => {

        if (!res.status) {
          // eslint-disable-next-line no-console
          console.error("ERROR", res)
          toast.error(res.message[0].toUpperCase() + res.message.slice(1))

        } else {
          toast.success("Registrazione completata")

          setTimeout(() => {
            navigate("/SignupComplete")
          }, 1000)
        }

      })
      .catch(err => {
        toast.error("Errore: " + err.message[0].toUpperCase() + err.message.slice(1))
        // eslint-disable-next-line no-console
        console.error("ERROR", { err })
      })
  }

  return (
    <Container
      className="container--single !p-5"
    >
      <div className="glassmorphism py-4">
        <Typography variant="h4" gutterBottom className="!ml-[3%] text-white">
          Registrazione
        </Typography>

        <form ref={formRef} autoComplete="off" className="flex flex-col gap-3 p-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
            {
              ["nome", "cognome"].map((field, i) => (
                <TextField
                  key={field + i}
                  sx={{ width: "80%", margin: "auto" }}
                  id={field}
                  name={field.toUpperCase()}
                  label={field[0].toUpperCase() + field.slice(1)}
                  error={isErrorInput[field.toUpperCase()]}
                  required
                />
              ))
            }
          </div>

          <hr className="w-2/3 bg-cyan mx-auto"/>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
            <TextField
              sx={{ width: "80%", margin: "auto" }}
              id="email"
              name="EMAIL"
              label="Email"
              type="email"
              inputMode="email"
              error={isErrorInput.EMAIL}
              required
            />
            <DatePicker
              value={birthDate.date}
              className={"form__input !mx-auto" + (isErrorInput.DATANASCITA ? " datepicker--error" : "")}
              onChange={(date) => {
                if (date === "Invalid Date") {
                  return
                }

                const today = new Date()
                const [day, month, year] = date.split("-")

                const isMinorenne = !!(today.getFullYear() - (+year) < 18 ||
                  (today.getFullYear() - (+year) === 18 && (today.getMonth() + 1) < (+month)) ||
                  (today.getFullYear() - (+year) === 18 && (today.getMonth() + 1) === (+month) && today.getDate() < (+day))
                )

                setBirthDate({ key: "date", value: date })
                setBirthDate({ key: "isMinorenne", value: isMinorenne })
              }}
              onError={(error) => {
                if (error) {
                  dispatchIsErrorInput({ key: "DATANASCITA", value: true })
                }
              }}
            />
          </div>

          <hr className="w-2/3 bg-cyan mx-auto"/>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
            <TextField
              sx={{ width: "80%", margin: "auto" }}
              id="displayname"
              name="DISPLAYNAME"
              label="Display name"
              type="text"
              error={isErrorInput.DISPLAYNAME}
              required
            />

            <TextField
              sx={{ width: "80%", margin: "auto" }}
              // disabled={!!params.rfid}
              InputProps={{
                readOnly: !!params.rfid
              }}
              id="rfid"
              label="RFID"
              name="RFID"
              type="number"
              datatype="number"
              value={params.rfid}
              error={isErrorInput.RFID}
            />

            <TextField
              sx={{ width: "80%", margin: "auto" }}
              id="password"
              name="PASSWORD_SITE"
              label="Password"
              type="password"
              error={isErrorInput.PASSWORD_SITE}
              required
            />

            <TextField
              sx={{ width: "80%", margin: "auto" }}
              id="password-conf"
              name="PASSWORDCONF"
              label="Conferma Password"
              type="password"
              error={isErrorInput.PASSWORDCONF}
              required
            />
          </div>

          <div>
            <Checkbox
              id="privacy"
              name="PRIVACY"
              className={isErrorInput.PRIVACY ? "checkbox--error" : ""}
            />
            <label className="text-white">Privacy</label>
          </div>

          <MinorenneForm show={birthDate.isMinorenne} isErrorInput={isErrorInput}/>

          <hr className="w-2/3 bg-cyan mx-auto my-3"/>

          <div className="text-white w-4/5 mx-auto">
            Dopo aver premuto Registrati, controlla la mail qui indicata per confermare le tua registrazione
          </div>

          <div className="text-center mt-2">
            <Button
              className="w-2/3 !mx-auto"
              variant="outlined"
              size="large"
              onClick={handleSubmit}
            >
              Registrati
            </Button>
          </div>
        </form>
      </div>
    </Container>
  )
}

export default SignupForm
